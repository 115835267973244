<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <b-collapse id="collapse-1" class="mt-2 col-12">
          <div class="p-2 text-center" v-if="loading">
            <b-card class="">
              <b-skeleton animation="wave" width="85%"></b-skeleton>
              <b-skeleton animation="wave" width="67%"></b-skeleton>
              <b-skeleton animation="wave" width="70%"></b-skeleton>
              <b-skeleton animation="wave" width="82%"></b-skeleton>
              <b-skeleton animation="wave" width="65%"></b-skeleton>
              <b-skeleton animation="wave" width="90%"></b-skeleton>
              <b-skeleton animation="wave" width="95%"></b-skeleton>
              <b-skeleton type="button"></b-skeleton>
            </b-card>
          </div>

          <div class="row" v-else>
            <div
              class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing"
            >
              <div class="widget-three">
                <div class="widget-content">
                  <div class="order-summary">
                    <div class="summary-list">
                      <div class="w-icon">
                        <i class="fa fa-credit-card text-success"></i>
                      </div>
                      <div class="w-summary-details">
                        <div class="w-summary-info">
                          <h6>Paid with service delivered</h6>
                          <p class="summary-count">
                            {{ formatMoney(this.paid_and_delivered) }}
                          </p>
                        </div>

                        <div class="w-summary-stats">
                          <div class="progress">
                            <div
                              class="progress-bar bg-gradient-success"
                              role="progressbar"
                              :style="{
                                width:
                                  computePercentage(this.paid_and_delivered) +
                                  '%',
                              }"
                              aria-valuenow="90"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="summary-list">
                      <div class="w-icon">
                        <i class="fa fa-credit-card text-info"></i>
                      </div>
                      <div class="w-summary-details">
                        <div class="w-summary-info">
                          <h6>Paid but service not delivered</h6>
                          <p class="summary-count">
                            {{ formatMoney(this.paid_not_delivered) }}
                          </p>
                        </div>

                        <div class="w-summary-stats">
                          <div class="progress">
                            <div
                              class="progress-bar bg-gradient-info"
                              role="progressbar"
                              :style="{
                                width:
                                  computePercentage(this.paid_not_delivered) +
                                  '%',
                              }"
                              aria-valuenow="65"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="summary-list">
                      <div class="w-icon">
                        <i class="fa fa-credit-card text-warning"></i>
                      </div>
                      <div class="w-summary-details">
                        <div class="w-summary-info">
                          <h6>Not paid but service delivered</h6>
                          <p class="summary-count">
                            {{ formatMoney(this.not_paid_but_delivered) }}
                          </p>
                        </div>

                        <div class="w-summary-stats">
                          <div class="progress">
                            <div
                              class="progress-bar bg-gradient-warning"
                              role="progressbar"
                              :style="{
                                width:
                                  computePercentage(
                                    this.not_paid_but_delivered
                                  ) + '%',
                              }"
                              aria-valuenow="80"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="summary-list">
                      <div class="w-icon">
                        <i class="fa fa-credit-card text-danger"></i>
                      </div>
                      <div class="w-summary-details">
                        <div class="w-summary-info">
                          <h6>Not paid and service not delivered</h6>
                          <p class="summary-count">
                            {{ formatMoney(this.not_paid_not_delivered) }}
                          </p>
                        </div>

                        <div class="w-summary-stats">
                          <div class="progress">
                            <div
                              class="progress-bar bg-gradient-danger"
                              role="progressbar"
                              :style="{
                                width:
                                  computePercentage(
                                    this.not_paid_not_delivered
                                  ) + '%',
                              }"
                              aria-valuenow="80"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing"
            >
              <div class="widget widget-card-four">
                <div class="widget-content">
                  <div id="chart">
                    <apexchart
                      type="pie"
                      width="380"
                      :options="chartOptions"
                      :series="series"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 layout-spacing"
            >
              <div class="widget widget-card-four">
                <b-card
                  border-variant="primary"
                  header="Total Amount For this Period"
                  header-bg-variant="primary"
                  header-text-variant="white"
                  align="center"
                >
                  <b-card-text>{{ formatMoney(this.totalAmount) }}</b-card-text>
                </b-card>

                <b-card
                  border-variant="success"
                  header="GeroCare Estimated Earnings For this Period"
                  header-bg-variant="success"
                  header-text-variant="white"
                  align="center"
                  class="mt-2"
                >
                  <b-card-text>{{
                    formatMoney(this.gerocareAmount)
                  }}</b-card-text>
                </b-card>
              </div>
            </div>
          </div>
        </b-collapse>

        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="text-right">
            <b-button v-b-toggle.collapse-1 variant="dark"
              ><i class="fa fa-eye"></i
            ></b-button>
            <button
              type="button"
              class="btn btn-primary mb-2 ml-2"
              data-toggle="modal"
              data-target="#searchModal"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>

          <div class="widget-content widget-content-area br-6">
            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="5"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div class="table-responsive mb-4 mt-4" v-else>
              <table
                id="medical-requests-table"
                class="table table-hover table-bordered"
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Pay Status</th>
                    <th>Delivery Status</th>
                    <th>Vendors Paid</th>
                    <th>Elderly Name</th>
                    <th>Elderly Phone</th>
                    <th>Sponsor Name</th>
                    <th>Sponsor Email</th>
                    <th>Sponsor Phone</th>
                    <th>Doctor Name</th>
                    <th>Doctor Email</th>
                    <th>Doctor Phone</th>
                    <th>Total</th>
                    <th>Overall Cost</th>
                    <th>Paid from Wallet</th>
                    <th>Sponsor's Response</th>
                    <th>Doctor's Comment</th>
                    <th>Update</th>
                    <th>Date Paid</th>
                    <th>Invoice Number</th>
                    <th>Date Delivered</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="record in records"
                    :key="record.id"
                    :set="(paired_prescription = record.paired_prescription)"
                    :class="tableRowClass(record)"
                  >
                    <td>{{ formatDateTime(record.created_at) }}</td>
                    <td>
                      <span
                        class="p-2 badge"
                        :class="
                          record.pay_status === 'paid'
                            ? 'badge-success'
                            : record.pay_status === 'paid not confirmed'
                            ? 'badge-warning'
                            : 'badge-danger'
                        "
                      >
                        {{
                          record.pay_status === "paid"
                            ? "Paid"
                            : record.pay_status === "paid not confirmed"
                            ? "paid not confirmed"
                            : "Not Paid"
                        }}
                      </span>
                      <hr v-if="paired_prescription" />
                      <span
                        class="p-2 badge"
                        :class="
                          record.paired_prescription.pay_status === 'paid'
                            ? 'badge-success'
                            : record.paired_prescription.pay_status ===
                              'paid not confirmed'
                            ? 'badge-warning'
                            : 'badge-danger'
                        "
                        v-if="paired_prescription"
                      >
                        {{
                          record.paired_prescription.pay_status === "paid"
                            ? "Paid"
                            : record.paired_prescription.pay_status ===
                              "paid not confirmed"
                            ? "paid not confirmed"
                            : "Not Paid"
                        }}
                      </span>
                    </td>
                    <td>
                      <span
                        class="badge p-2"
                        :class="
                          record.delivery_status == 'delivered'
                            ? 'badge-success'
                            : 'badge-danger'
                        "
                      >
                        {{ renderDeliveryStatus(record) }}
                      </span>
                      <hr v-if="paired_prescription" />
                      <span
                        class="badge p-2"
                        :class="
                          record.paired_prescription.delivery_status ==
                          'delivered'
                            ? 'badge-success'
                            : 'badge-danger'
                        "
                        v-if="paired_prescription"
                      >
                        {{
                          record.paired_prescription.delivery_status ==
                          "delivered"
                            ? ""
                            : "Not"
                        }}
                        Delivered
                      </span>
                    </td>
                    <td>
                      <span
                        :class="
                          'badge badge-' +
                          (record.pay_vendors ? 'success' : 'danger')
                        "
                      >
                        {{ renderVendorPaymentStatus(record) }}
                      </span>
                      <hr v-if="paired_prescription" />
                      <span
                        v-if="paired_prescription"
                        :class="
                          'badge badge-' +
                          (paired_prescription.pay_vendors
                            ? 'success'
                            : 'danger')
                        "
                      >
                        {{ paired_prescription.pay_vendors ? "yes" : "no" }}
                      </span>
                    </td>
                    <td>
                      {{ record.elderly_name }}
                      <hr v-if="paired_prescription" />
                      <span v-if="paired_prescription">{{
                        paired_prescription.elderly_name
                      }}</span>
                    </td>
                    <td>
                      {{ record.elderly_phone }}
                      <hr v-if="paired_prescription" />
                      <span v-if="paired_prescription">{{
                        paired_prescription.elderly_phone
                      }}</span>
                    </td>
                    <td>
                      {{ record.sponsor_name }}<br />
                      <b-badge
                        variant="dark"
                        class="mt-2 p-2"
                        v-if="paired_prescription"
                        title="Cost Breakdown"
                        tabindex="0"
                        v-b-popover.click.html="
                          `
                            <table>
                              <tr>
                                <th style='padding: 5px;'>Overall</th>
                                <td>` +
                          formatMoney(
                            record.overall_total +
                              paired_prescription.overall_total
                          ) +
                          `</td>
                              </tr>
                              <tr>
                                <th style='padding: 5px;'>Logistics</th>
                                <td>` +
                          formatMoney(record.invoice.overall_logistics_total) +
                          `</td>
                              </tr>
                              <tr style='border-top: 2px double #909a9f;'>
                                <th style='padding: 5px;'></th>
                                <td>` +
                          formatMoney(
                            record.overall_total +
                              paired_prescription.overall_total -
                              record.invoice.overall_logistics_total
                          ) +
                          `</td>
                              </tr>
                            </table>
                          `
                        "
                      >
                        {{
                          formatMoney(
                            record.overall_total +
                              paired_prescription.overall_total -
                              record.invoice.overall_logistics_total
                          )
                        }}
                      </b-badge>
                    </td>
                    <td>{{ record.sponsor_email }}</td>
                    <td>{{ record.sponsor_phone }}</td>
                    <td>{{ record.doctor_name }}</td>
                    <td>{{ record.doctor_email }}</td>
                    <td>{{ record.doctor_phone }}</td>
                    <td>
                      <span
                        :title="formatMoney(record.total_usd, 'USD')"
                        v-b-tooltip.hover
                      >
                        {{ formatMoney(record.total) }}
                      </span>
                      <hr v-if="paired_prescription" />
                      <span
                        v-if="paired_prescription"
                        :title="
                          formatMoney(paired_prescription.total_usd, 'USD')
                        "
                        v-b-tooltip.hover
                      >
                        {{ formatMoney(paired_prescription.total) }}
                      </span>
                    </td>
                    <td>
                      <span
                        :title="formatMoney(record.overall_total_usd, 'USD')"
                        v-b-tooltip.hover
                      >
                        {{ formatMoney(record.overall_total) }}
                      </span>
                      <hr v-if="paired_prescription" />
                      <span
                        v-if="paired_prescription"
                        :title="
                          formatMoney(
                            paired_prescription.overall_total_usd,
                            'USD'
                          )
                        "
                        v-b-tooltip.hover
                      >
                        {{ formatMoney(paired_prescription.overall_total) }}
                      </span>
                    </td>
                    <td>
                      {{
                        formatMoney(
                          record.amount_paid_from_wallet +
                            (record.paired_prescription
                              ? record.paired_prescription
                                  .amount_paid_from_wallet
                              : 0)
                        )
                      }}
                    </td>
                    <td>{{ record.sponsor_response }}</td>
                    <td>{{ record.doctor_comment }}</td>
                    <td>{{ record.update }}</td>
                    <td>
                      <span>{{
                        record.date_paid ? formatDateTime(record.date_paid) : ""
                      }}</span>
                      <hr v-if="paired_prescription" />
                      <span v-if="paired_prescription">
                        {{
                          paired_prescription.date_paid
                            ? formatDateTime(paired_prescription.date_paid)
                            : ""
                        }}
                      </span>
                    </td>
                    <td>
                      {{ record.invoice.invoice_no }}
                      <span v-if="paired_prescription">
                        <hr />
                        {{ record.paired_prescription.invoice.invoice_no }}
                      </span>
                    </td>
                    <td>
                      <span>{{
                        record.date_delivered
                          ? formatDateTime(record.date_delivered)
                          : ""
                      }}</span>
                      <hr v-if="paired_prescription" />
                      <span v-if="paired_prescription">
                        {{
                          paired_prescription.date_delivered
                            ? formatDateTime(paired_prescription.date_delivered)
                            : ""
                        }}
                      </span>
                    </td>
                    <td>
                      <b-button-group size="sm">
                        <b-btn
                          variant="primary"
                          title="View"
                          :to="{
                            name: 'View Medical Request',
                            params: { id: record.id },
                          }"
                          v-b-tooltip.hover
                        >
                          <i class="fa fa-eye"></i>
                        </b-btn>
                        <b-btn
                          variant="info"
                          @click.prevent="showCallsModal(record)"
                          v-if="record.calls.length > 0"
                          title="Show Calls"
                          v-b-tooltip.hover
                        >
                          <i class="fa fa-phone"></i>
                        </b-btn>
                        <b-btn
                          variant="dark"
                          target="_blank"
                          @click="openInvoiceTab(record)"
                          title="Open Invoice"
                          v-b-tooltip.hover
                        >
                          <i
                            class="fa"
                            :class="
                              paired_prescription
                                ? 'fa-file-archive'
                                : 'fa-file'
                            "
                          ></i>
                        </b-btn>
                        <b-btn
                          variant="success"
                          @click.prevent="showResendModal(record)"
                          v-if="
                            record.invoice &&
                            record.pay_status != 'paid' &&
                            (paired_prescription
                              ? paired_prescription.pay_status != 'paid'
                              : true) &&
                            record.closed_at == null
                          "
                          title="Resend Invoice"
                          v-b-tooltip.hover
                        >
                          <i class="fa fa-paper-plane"></i>
                        </b-btn>
                        <b-btn
                          v-if="
                            paired_prescription &&
                            user.role == 'medical services' &&
                            record.closed_at == null
                          "
                          variant="light"
                          @click.prevent="showSplitModal(record)"
                          title="Split Paired Request"
                          v-b-tooltip.hover
                        >
                          <i class="fa fa-people-arrows"></i>
                        </b-btn>
                        <b-btn
                          variant="warning"
                          :to="`/medicalrequests/edit/${record.id}`"
                          title="Update Request"
                          v-if="
                            user.role == 'medical services' &&
                            record.closed_at == null
                          "
                          v-b-tooltip.hover
                        >
                          <i class="fa fa-edit"></i>
                        </b-btn>
                        <b-btn
                          variant="danger"
                          @click.prevent="showDeleteModal(record)"
                          title="Delete Request"
                          v-if="
                            user.role == 'medical services' &&
                            record.closed_at == null
                          "
                          v-b-tooltip.hover
                        >
                          <i class="fa fa-trash"></i>
                        </b-btn>
                      </b-button-group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="searchModal"
        role="dialog"
        aria-labelledby="searchModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="searchModalLabel">Search Options</h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="dateSearch">Date Search</label>
                    <select
                      class="form-control"
                      v-model="search"
                      id="dateSearch"
                    >
                      <option value="all">All</option>
                      <option value="thisweek">This Week (From Monday)</option>
                      <option value="pastweek">Past Week (Last 7 days)</option>
                      <option value="lastmonth">Last Month</option>
                      <option value="thismonth">This Month</option>
                      <option value="thisyear">This Year</option>
                      <option value="custom">Custom</option>
                    </select>
                  </div>

                  <div class="form-group" v-if="search == 'custom'">
                    <label>Select a range</label>
                    <div class="input-group">
                      <flat-pickr
                        v-model="date"
                        :config="config"
                        class="form-control"
                        placeholder="Select date"
                        @on-close="handlePicker()"
                        name="date"
                      >
                      </flat-pickr>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn" data-dismiss="modal">
                <i class="flaticon-cancel-12"></i> Discard
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="handleSearch()"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="callsModal"
        role="dialog"
        aria-labelledby="callsModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="mr-auto">
                <small class="modal-title" id="callsModalLabel">
                  All Followup Records
                </small>
              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-12 col-12 layout-spacing">
                  <div class="table-responsive" v-if="displayCalls.length > 0">
                    <table class="table table-bordered mb-4">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Update</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="call in displayCalls" :key="call.id">
                          <td>
                            {{ call.user.firstname || "" }}
                            {{ call.user.lastname || "" }}
                          </td>
                          <td>{{ formatDate(call.created_at) }}</td>
                          <td>
                            <b-badge
                              v-if="call.status == 'proceed'"
                              variant="success"
                              class="p-2"
                            >
                              Proceed
                            </b-badge>
                            <b-badge
                              v-if="call.status == 'proceedpayfromwallet'"
                              variant="success"
                              class="p-2"
                            >
                              Proceed & Pay From Wallet
                            </b-badge>
                            <b-badge
                              v-if="call.status == 'sponsor will handle'"
                              variant="info"
                              class="p-2"
                            >
                              Sponsor will handle
                            </b-badge>
                            <b-badge
                              v-if="call.status == 'pending'"
                              variant="dark"
                              class="p-2"
                            >
                              Pending reply
                            </b-badge>
                            <b-badge
                              v-if="call.status == 'declined'"
                              variant="danger"
                              class="p-2"
                            >
                              Sponsor Declined
                            </b-badge>
                            <b-badge
                              v-if="call.status == 'exceeded'"
                              variant="warning"
                              class="p-2"
                            >
                              Exceeded 48 hours
                            </b-badge>
                          </td>
                          <td>{{ call.update }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-dark ml-auto" data-dismiss="modal">
                <i class="flaticon-cancel-12"></i> Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import { DatePickerConfig } from "@/models/datepicker.js";
import { mapGetters } from "vuex";
import $ from "jquery";

export default {
  name: "allMedicalRequests",

  computed: {
    ...mapGetters(["url", "user"]),
  },

  data() {
    return {
      loading: false,
      records: [],

      search: "thismonth",
      date: null,
      config: new DatePickerConfig(),
      customStart: "",
      customEnd: "",

      displayCalls: [],

      series: [],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        colors: ["#27d488", "#0906b3", "#d0c10b", "#af0a0a"],
        labels: [
          "Paid and Delivered",
          "Paid not Delivered",
          "Not Paid but Delivered",
          "Not Paid Not Delivered",
        ],
      },
      totalAmount: 0,
      gerocareAmount: 0,
      paid_and_delivered: 0,
      paid_not_delivered: 0,
      not_paid_but_delivered: 0,
      not_paid_not_delivered: 0,
      paid_and_delivered_percentage: 0,
      paid_not_delivered_percentage: 0,
      not_paid_but_delivered_percentage: 0,
      not_paid_not_delivered_percentage: 0,
    };
  },

  mounted() {
    this.fetchRecords();
  },

  methods: {
    renderDeliveryStatus(record) {
      if (record.delivery_status == "delivered") {
        return "Delivered";
      }

       if (record.delivery_status == "not delivered") {
        return "Not Delivered";
      }

      if (
        record.delivery_status != "delivered" &&
        record.total_delivered_invoice_items > 0
      ) {
        return `Partially Delivered (${
          record.total_delivered_invoice_items +
          "/" +
          record.total_invoice_items
        })`;
      }

      return "Delivered";
    },
    renderVendorPaymentStatus(record) {
      if (record.pay_vendors) {
        return "Yes";
      }

      if (!record.pay_vendors && record.total_paid_invoice_items > 0) {
        return `No (${
          record.total_paid_invoice_items + "/" + record.total_invoice_items
        })`;
      }

      return "No";
    },
    tableRowClass(row) {
      if (row.closed_at) {
        return "table-warning";
      }

      if (row.paired_prescription) {
        return "table-secondary";
      }

      if (row.subject_tag) {
        return "table-info";
      }
    },

    initTable() {
      var xyz = $("#medical-requests-table").DataTable({
        retrieve: true,
        dom: "<'d-md-flex justify-content-between'<'d-md-flex'<l><'ml-2'B>><f>>tipr",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        order: [[0, "desc"]],
      });
      xyz
        .columns([3, 5, 7, 8, 9, 10, 11, 14, 15, 16, 17, 18, 19])
        .visible(false);
    },

    fetchRecords() {
      this.loading = true;
      http
        .get(endpoints.GET_ALL_PRESCRIPTION_RECORDS, {
          params: {
            search: this.search,
            customStart: this.customStart,
            customEnd: this.customEnd,
          },
        })
        .then((response) => {
          this.records = response;

          this.rebuildInfoValues();

          this.loading = false;
          setTimeout(() => {
            this.initTable();
          }, 500);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    handlePicker() {
      setTimeout(() => {
        if (this.date.length < 15) {
          this.$toast.error("Enter a valid date range");
        } else {
          var split = this.date.split(" ");
          this.customStart = split[0];
          this.customEnd = split[2];
        }
      }, 500);
    },

    handleSearch() {
      if (
        this.search == "custom" &&
        (this.customStart == "" || this.customEnd == "")
      ) {
        this.$toast.error("Enter a valid date range");
      } else {
        this.fetchRecords();
        $("#searchModal").modal("hide");
      }
    },

    resetInfoValues() {
      this.totalAmount = 0;
      this.gerocareAmount = 0;
      this.paid_and_delivered = 0;
      this.paid_not_delivered = 0;
      this.not_paid_but_delivered = 0;
      this.not_paid_not_delivered = 0;
    },

    computePercentage(x) {
      return ((100 * x) / this.totalAmount) * 2;
    },

    showResendModal(record) {
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to resend this invoice to ${record.sponsor_name}`,
          {
            title: "Are You Sure?",
            size: "md",
            buttonSize: "sm",
            okVariant: "success",
            okTitle: "YES",
            cancelTitle: "NO",
            cancelVariant: "outline-dark",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.handleResend(record.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleResend(id) {
      http
        .get(endpoints.RESEND_PRESCRIPTION_INVOICE.replace(":id", id))
        .then((response) => {
          this.$toast.success(response);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    showCallsModal(x) {
      this.displayCalls = x.calls;
      $("#callsModal").modal("show");
    },

    showDeleteModal(record) {
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to delete this medical request for
            ${record.elderly_name}` +
            (record.paired_prescription
              ? `and ${record.paired_prescription.elderly_name}`
              : ""),
          {
            title: "Are You Sure?",
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            cancelVariant: "outline-dark",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.deletePrescription(record.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deletePrescription(id) {
      http
        .delete(endpoints.DELETE_PRESCRIPTION_INVOICE.replace(":id", id))
        .then((response) => {
          this.$toast.success(response);
          this.fetchRecords();
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    openInvoiceTab(record) {
      window.open(record.invoice.filename);

      if (record.paired_prescription) {

        const filePath = record.paired_prescription_file != null
          ? record.paired_prescription_file
          : record.paired_prescription.invoice.filename

        window.open(filePath);
      }
    },

    showSplitModal(record) {
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to split the paired prescription for
        ${record.elderly_name} and ${record.paired_prescription.elderly_name}`,
          {
            title: "Are You Sure?",
            size: "md",
            buttonSize: "sm",
            okVariant: "warning",
            okTitle: "YES",
            cancelTitle: "NO",
            cancelVariant: "outline-dark",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.splitPrescription(record.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    splitPrescription(id) {
      http
        .patch(endpoints.SPLIT_PRESCRIPTION_INVOICE.replace(":id", id))
        .then((response) => {
          this.$toast.success(response);
          this.fetchRecords();
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    rebuildInfoValues() {
      this.resetInfoValues();

      this.records.forEach((record) => {
        let total = record.total;
        let overall_total = record.overall_total;

        if (record.paired_prescription) {
          total =
            total +
            record.paired_prescription.total -
            record.invoice.logistics_total;
          overall_total =
            overall_total +
            record.paired_prescription.overall_total -
            record.invoice.overall_logistics_total;
        }

        this.totalAmount += overall_total;
        this.gerocareAmount += overall_total - total;

        if (
          record.pay_status == "paid" &&
          record.delivery_status == "delivered"
        ) {
          this.paid_and_delivered += Number(overall_total);
        }

        if (
          record.pay_status == "paid" &&
          record.delivery_status == "not delivered"
        ) {
          this.paid_not_delivered += Number(overall_total);
        }

        if (
          record.pay_status == "not paid" &&
          record.delivery_status == "delivered"
        ) {
          this.not_paid_but_delivered += Number(overall_total);
        }

        if (
          record.pay_status == "not paid" &&
          record.delivery_status == "not delivered"
        ) {
          this.not_paid_not_delivered += Number(overall_total);
        }
      });

      this.computePercentage();

      this.series = [
        this.paid_and_delivered,
        this.paid_not_delivered,
        this.not_paid_but_delivered,
        this.not_paid_not_delivered,
      ];
    },
  },
};
</script>
